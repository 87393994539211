import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

// These are currently listed in popularity order
const iconConverter = {
  'fa-info-circle': 'alert-info',
  'fa-plus-circle': 'action-add',
  'fa-pencil': 'action-edit',
  'fa-question-circle': 'action-help',
  'fa-exclamation-triangle': 'alert-warning',
  'fa-times': 'action-close',
  'fa-trash': 'action-delete',
  'fa-check': 'action-confirm',
  'fa-check-circle': 'alert-success',
  'fa-clock-o': 'action-view-time',
  'fa-exclamation-circle': 'alert-error',
  'fa-plus': 'plus',
  'fa-trash-o': 'action-delete',
  'fa-minus-circle': 'action-subtract',
  'fa-chevron-down': 'action-expand',
  'fa-download': 'action-download',
  'fa-chevron-right': 'nav-page-next',
  'fa-eye': 'action-show',
  'fa-warning': 'alert-warning',
  'fa-file-text-o': 'media-file',
  'fa-star': 'feedback-rate-empty',
  'fa-times-circle': 'action-close',
  'fa-caret-down': 'action-menu-open',
  'fa-caret-up': 'action-menu-close',
  'fa-circle': 'ext-mdi-circle',
  'fa-arrow-down': 'action-sort-desc',
  'fa-money': 'brand-payments',
  'fa-filter': 'action-filter',
  // 'fa-hand-o-right': 'none', No replacement yet
  'fa-history': 'action-history',
  'fa-user': 'user',
  'fa-calendar': 'ext-mdi-calendar-month',
  'fa-external-link': 'external-link',
  'fa-angle-double-right': 'nav-page-next',
  'fa-refresh': 'action-refresh',
  'fa-envelope': 'action-email',
  'fa-sort': 'action-sort',
  // 'fa-spinner': 'none', No replacement yet
  'fa-chevron-left': 'nav-page-back',
  'fa-users': 'user-group',
  'fa-chevron-circle-right': 'nav-page-next',
  'fa-file-pdf-o': 'media-file-pdf',
  'fa-angle-down': 'nav-page-up',
  'fa-arrow-left': 'nav-move-left',
  'fa-search': 'action-search',
  'fa-flag': 'status-flagged',
  'fa-angle-right': 'nav-page-next',
  'fa-save': 'action-save',
  'fa-close': 'action-close',
  'icon-block': 'ext-mdi-block',
  'fa-arrow-up': 'action-sort-asc',
  'fa-star-o': 'feedback-rate-empty',
  'fa-ban': 'ext-mdi-block',
  'fa-sort-asc': 'action-sort-asc',
  'fa-check-circle-o': 'alert-success',
  'fa-caret-right': 'action-tree-expand',
  'fa-map-marker': 'location',
  'fa-upload': 'action-upload',
  'icon-vacation-timeoff': 'pto-vacation',
  'fa-print': 'action-print',
  'fa-chevron-up': 'action-collapse',
  'fa-phone': 'phone',
  // 'fa-table': 'none', No replacement yet
  // 'fa-calculator': 'none', No replacement yet
  'fa-arrow-right': 'nav-move-right',
  'fa-home': 'home',
  'fa-exchange': 'action-swap',
  // 'fa-sticky-note': 'none', No replacement yet
  'fa-arrow-circle-right': 'nav-move-right',
  // 'fa-bolt': 'none', No replacement yet
  'icon-close-thin': 'action-close',
  'fa-sort-desc': 'action-sort-desc',
  'fa-cog': 'action-view-settings',
  // 'fa-flag-checkered': 'none', No replacement yet
  'fa-lock': 'status-locked',
  'fa-university': 'institution-bank',
  'fa-ellipsis-v': 'action-more-horiz',
  'fa-file': 'media-file',
  'fa-file-o': 'media-file',
  'fa-mobile': 'ext-mdi-smartphone',
  'fa-times-circle-o': 'ext-mdi-dangerous',
  'fa-list-alt': 'action-view-list',
  'icon-reg-pay': 'brand-payments',
  'icon-retirement': 'retirement',
  'fa-sitemap': 'ext-mdi-ian',
  'fa-stethoscope': 'brand-medical',
  'fa-angle-up': 'action-collapse',
  'fa-list': 'ext-mdi-format-list-bulleted',
  'icon-payroll': 'brand-payroll',
  'fa-cloud-upload': 'action-upload-cloud',
  'fa-comment': 'action-view-comment',
  'fa-file-excel-o': 'media-file-excel',
  'fa-paper-plane-o': 'action-send',
  'icon-overtime-shift': 'ext-mdi-more-time'
};

export const iconMapper = (className: string): IconName | undefined => {
  const isADPUnified = !window['isLegacyAppShell'];

  // Don't convert icons that are in the legacy app shell because they won't appear.
  if (!isADPUnified) {
    return undefined;
  }

  const iconClasses = className?.split(' ')
    .filter((name) => (name === 'fa' || name.includes('fa-') || name.includes('icon-')));

  const mappedIcon = iconClasses?.map((icon: string) => iconConverter[icon]).filter((icon: string) => !!icon);

  if (mappedIcon?.length > 1) {
    console.error(`iconMapper(): className "${className}" contains more than 1 icon`);
  }

  return mappedIcon?.[0];
};
