import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import accepts from '../util/accepts';
import { ProgressBar } from '@synerg/vdl-react-components';
import classNames from 'classnames';
import { MDFButton } from './MDFButton';
import { MDFModalDialog } from './MDFModalDialog';
import { MDFIcon } from './MDFIcon';
import { DeviceHelper, FormatHelper, generateId, LocaleHelper } from '@adp-wfn/mdf-core';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IMobileUploadResponse {
  base64Img: string;
  isMobile: boolean;
  message: string;
  success: boolean;
  apiResponse: any;
}

// Notes: Once the file upload is complete the component will be sending the data back to the application
// using uploadComplete property with this structure: { state: this.state.items, XHRresponse: this.xhrs }
// and the application can read from the XHRresponse and show the results. The application can use a custom
// view to show the fileset by setting 'renderFileSet' to false and have the application view handle the file set.
// MetaData: const metaDataObject = { json: JSON.stringify(displayName: 'displayName', fileName: 'filename', documentType: '', documentStatus: 'CLEAR'}), uploadFile: 'uploadFile.png'}
export interface IMDFFileUploadProps {
  // Allowed file format
  acceptTypes?: any;
  // Do not hide the DropZone when maxFiles is set to 1 and this property is set to true
  allowMoreUploads?: boolean;
  // property to show/hide the upload button.
  auto?: boolean;
  // property to disable/enable the upload component
  disabled?: boolean;
  // The label for the components button.
  buttonLabel?: string;
  // custom icon for cancel
  cancelIconClass?: string
  // The size of chunks to use if chunking is enabled.
  chunkSize?: number;
  // Whether to use chunking or not.
  chunks?: boolean;
  // Pass a custom icon once the upload is complete
  completeIconClass?: string;
  // Application can control what should happen when the file is cancelled.
  deleteFile?: (item) => void;
  // customize the dropTarget
  dropTargetZone?: boolean;
  // Customize the dropTargetStyle
  dropTargetStyle?: string;
  // The label for the components drop zone label
  dropzoneLabel?: string;
  // property to pass the fieldName to the formData
  fieldName?: string;
  // To style fileIcon
  fileIconClass?: string;
  // To style FileName
  fileNameStyle?: string;
  // custom style for rendering the fileSet
  filesetStyle?: string;
  // Custom label for file size
  fileSizeLabel?: string;
  // To style the file size DOM
  fileSizeStyle?: string;
  // Custom error message property when the file exceeds the size
  fileSizeExceedErrorMessage?: string;
  // The maximum number of files to allow.
  maxFiles?: number;
  // maxSize allowed for an item.
  maxSize?: number;
  // Application can add form data to the file upload post. The structure of metaData should be object with key and value pairs.
  metaData?: any;
  // The http method to use for the upload. ex. POST, GET etc
  method?: string;
  // Props to send the file items back to application
  onFileSelect?: any;
  // Callback fired to send the response to the application
  onLoadComplete?: (response) => void;
  // Added for Mobile uploads
  onUploadStart?: () => void;
  // Customize progressBar
  progressClass?: string;
  // whether to render the File set
  renderFileSet?: boolean;
  // Passing headers causes Potential data leakage. The application is responsible and should know what headers they are passing and should match the one with the response headers from the server.\nExpected structure should be a object with name and value pair. const headers = 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8', 'Accept': 'adptestHeader' */
  requestHeaders?: object;
  // show or hide upload Error Message
  showErrorMessage?: boolean;
  // for server error
  serverError?: boolean;
  // Message to display on successful upload
  successMessage?: string;
  // to customize the upload button
  uploadButtonStyle?: string;
  // Callback function gets triggered when the upload has completed
  uploadComplete?: (response) => void;
  // supports a custom upload icon class
  uploadIconClass?: string;
  // customize the root div for the upload
  uploadRootStyle?: string;
  // The server url to upload.
  url?: string;
  // Id applied for the upload
  uploadButtonId?: string;
  // property to change the 'UPLOAD' label
  uploadLabel?: string;
  // To enable the aria required attribute for accessibility.
  ariaRequired?: boolean;
  'aria-required'?: boolean;
}

export class MDFFileUpload extends React.Component<IMDFFileUploadProps, any> {
  private activeDrag;
  private xhrs;
  private fileInput;
  private currentIndex;
  private isDisabled = false;
  private isMobile = false;
  private mobileContainer: any;
  private selectedFiles: [];
  private uploadTextButtonLabelTranslation: string;
  private uploadButtonLabelTranslation: string;
  private dropzoneLabelTranslation: string;

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      dropTarget: this.props.dropTargetZone,
      modalOpen: false,
      hasSelectedFiles: false,
      isUploadSuccessful: false,
      uploadFailed: false
    };

    this.activeDrag = 0;
    this.xhrs = [];
    this.isMobile = DeviceHelper.isADPMobileApp();
    this.mobileContainer = DeviceHelper.getADPMobileContainer();

    if (this.isMobile && !this.mobileContainer) {
      console.warn('ADP Mobile container was not detected.');
    }

    this.uploadTextButtonLabelTranslation = props.uploadLabel || FormatHelper.formatMessage('@@label_select_upload');
    this.uploadButtonLabelTranslation = props.buttonLabel || FormatHelper.formatMessage('@@Upload');
    this.dropzoneLabelTranslation = props.dropzoneLabel || FormatHelper.formatMessage('@@dropZoneLabel');
  }

  static propTypes = {
    url: PropTypes.string.isRequired,
    method: PropTypes.string,
    auto: PropTypes.bool,
    fieldName: PropTypes.string,
    buttonLabel: PropTypes.string,
    dropzoneLabel: PropTypes.string,
    uploadLabel: PropTypes.string,
    chunks: PropTypes.bool,
    chunkSize: PropTypes.number,
    maxFiles: PropTypes.number,
    uploadComplete: PropTypes.func,
    fileSetStyle: PropTypes.string,
    fileSizeStyle: PropTypes.string,
    fileSizeExceedErrorMessage: PropTypes.string,
    cancelIconClass: PropTypes.string,
    completeIconClass: PropTypes.string,
    uploadIconClass: PropTypes.string,
    uploadButtonId: PropTypes.string,
    progressClass: PropTypes.string,
    onLoadComplete: PropTypes.func,
    onUploadStart: PropTypes.func,
    uploadButtonStyle: PropTypes.string,
    uploadRootStyle: PropTypes.string,
    onFileSelect: PropTypes.any,
    renderFileSet: PropTypes.bool,
    dropTarget: PropTypes.bool,
    deleteFile: PropTypes.func,
    acceptTypes: PropTypes.string,
    successMessage: PropTypes.string,
    allowMoreUploads: PropTypes.bool,
    metaData: PropTypes.any,
    requestHeader: PropTypes.object,
    showErrorMessage: PropTypes.bool,
    ariaRequired: PropTypes.bool
  };

  static defaultProps = {
    method: 'POST',
    auto: false,
    fieldName: 'datafile',
    maxSize: 25 * 1024 * 1024,
    showErrorMessage: true,
    chunks: false,
    chunkSize: 512 * 1024,
    localStore: false,
    maxFiles: 1,
    encrypt: false,
    cancelIconClass: 'fa fa-close',
    completeIconClass: 'fa fa-trash',
    uploadIconClass: 'fa fa-upload',
    fileIconClass: 'fa fa-file-text',
    dropTargetZone: true,
    renderFileSet: true,
    allowMoreUploads: false
  };

  onClick = () => {
    // set the input value to empty to select same file again if needed to trigger the onChange event.
    this.fileInput.value = '';
    this.fileInput.click();
  };

  onUploadButtonClick = () => {
    this.upload();
  };

  handleMobileSelectClick = (_e: any) => {
    const batchSizeLimit = 15;
    let fileTypes = this.props.acceptTypes ? this.props.acceptTypes.replace(/\./g, '') : '';
    fileTypes = fileTypes ? fileTypes.split(',') : [];

    this.mobileContainer.FileManagement.selectFiles('MDFFileUpload', fileTypes, this.props.maxSize,
      this.props.maxFiles, batchSizeLimit,
      (files: any) => {
        this.mobileContainer.Logger.log('info', 'select files success: ' + JSON.stringify(files));

        // Let the app know what the user chose.
        this.props.onFileSelect?.(files);

        for (const file of files) {
          file.name = this.props.fieldName;
        }

        this.selectedFiles = files;

        this.setState({
          hasSelectedFiles: true
        });
      },
      (failure: any) => {
        this.mobileContainer.Logger.log('info', 'select files failure: ' + JSON.stringify(failure));

        this.setState({
          hasSelectedFiles: false
        });
      });
  };

  handleMobileUploadClick = (_e: any) => {
    this.props.onUploadStart?.();

    this.mobileContainer.FileManagement.uploadFiles('MDFFileUpload', this.selectedFiles, this.props.url,
      this.props.metaData || {}, [], 'multipart/mixed', (success: any) => {
        this.selectedFiles = [];

        this.setState({
          hasSelectedFiles: false,
          isUploadSuccessful: true,
          uploadFailed: false
        });

        const nativeResponse = JSON.parse(success);

        const response = Object.assign({}, {
          base64Img: nativeResponse.base64Img,
          isMobile: true,
          message: this.props.successMessage,
          success: true,
          apiResponse: nativeResponse
        });

        this.props.uploadComplete?.(response);
      },
      (failure: any) => {
        this.setState({
          isUploadSuccessful: false,
          uploadFailed: true
        });

        const maxFileSize = this.renderSize(this.props.maxSize / (1024 * 1024));
        const fileSize = {
          fileSize: maxFileSize
        };

        const response: IMobileUploadResponse = Object.assign({}, {
          base64Img: undefined,
          isMobile: true,
          message: this.props.fileSizeExceedErrorMessage || FormatHelper.formatMessage('@@fileSizeExceeded', fileSize),
          success: false,
          apiResponse: JSON.parse(failure)
        });

        this.props.uploadComplete?.(response);
      });

    this.setState({
      hasSelectedFiles: false
    });
  };

  onUploadFilesClick = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.onClick();
    }
  };

  onFileSelect = () => {
    if (this.fileInput.files.length > 0) {
      const items = this.filesToItems(this.fileInput.files);

      // Application need an event to know when the file starts to upload to show or hide the renderTargetZone accordingly
      if (items.length > 0) {
        this.props.onFileSelect?.(items);
      }

      this.setState({ items }, () => {
        if (this.props.auto) {
          this.upload();
        }
      });
    }
  };

  onDragEnter = (e) => {
    e?.preventDefault();
    this.activeDrag += 1;
    this.setState({ isActive: this.activeDrag > 0 });
  };

  onDragOver = (e) => {
    e?.preventDefault();
    return false;
  };

  onDragLeave = () => {
    this.activeDrag -= 1;

    if (this.activeDrag === 0) {
      this.setState({ isActive: false });
    }
  };

  onDrop = (e) => {
    if (!e) {
      return;
    }

    e.preventDefault();

    this.activeDrag = 0;
    const droppedFiles = e.dataTransfer ? e.dataTransfer.files : [];
    const items = this.filesToItems(droppedFiles);

    // Application need an event to know the file items when the files are dropped to the targetZone
    if (items.length > 0) {
      this.props.onFileSelect?.(items);
    }

    this.setState({ isActive: false, items }, () => {
      if (this.props.auto) {
        this.upload();
      }
    });
  };

  // User app should handle after upload
  uploadComplete = () => {
    const completed = this.state.items.filter((item) => item.progress === 100).length;
    const uploadedItems = this.xhrs.filter((item) => item !== undefined);

    if (completed === uploadedItems.length) {
      if (this.props.uploadComplete) {
        const dataUploaded = Object.assign({}, { state: this.state.items, XHRresponse: this.xhrs });
        this.props.uploadComplete(dataUploaded);
      }
    }
  };

  updateFileProgress = (index, progress) => {
    const newItems = [...this.state.items];
    newItems[index] = Object.assign({}, this.state.items[index], { progress });

    // Class components had an implementation quirk where it was possible to synchronously read state updates inside of events.
    // This means you would be able to read this.state between the calls to setState
    // In React 18, this is no longer the case. Since all of the updates even in setTimeout are batched, React doesn’t render the result of the first setState synchronously—the render occurs during the next browser tick. So the render hasn’t happened yet:
    // ReactDOM.flushSync uses to force an update so that we can read the latest values from this.state object
    ReactDOM.flushSync(() => {
      this.setState({ items: newItems });
    });
  };

  updateFileStatus = (index, status, statusText, responseText) => {
    const newItems = [...this.state.items];
    newItems[index] = Object.assign({}, this.state.items[index], { status }, { statusText }, { isError: status !== 200 }, { errorMessage: responseText });
    ReactDOM.flushSync(() => {
      this.setState({ items: newItems });
    });
  };

  updateFileChunkProgress = (index, chunkIndex, progress) => {
    const newItems = [...this.state.items];
    const currentItem = this.state.items[index];
    const newProgressArr = [...currentItem.chunkProgress];
    const totalProgress = newProgressArr.reduce((a, b) => a + b) / (newProgressArr.length - 1);

    // -1 because there is always single chunk for '0' percentage pushed as chunkProgress.push(0) in method filesToItems
    newProgressArr[chunkIndex] = progress;
    newItems[index] = Object.assign({}, currentItem, { chunkProgress: newProgressArr, progress: totalProgress });
    this.setState({ items: newItems }, this.uploadComplete);
  };

  cancelFile = (index) => {
    const newItems = [...this.state.items];
    newItems[index] = Object.assign({}, this.state.items[index], { cancelled: true });

    if (this.xhrs[index]) {
      this.xhrs[index].abort();
    }

    this.setState({ items: newItems });

    // set the input value to empty when the file upload is cancelled.
    if (this.fileInput) {
      this.fileInput.value = '';
    }

    // Enable the fileInput if the file upload is cancelled.
    this.fileInput.disabled = false;
    this.isDisabled = false;
  };

  deleteFile = () => {
    const newItems = [...this.state.items];
    const index = this.currentIndex;

    newItems[index] = Object.assign({}, this.state.items[index], { cancelled: true });

    this.setState({ items: newItems });

    // the application can decide what should happen once the file is cancelled.
    this.props.deleteFile?.(this.state.items[index].file);

    // set the input value to empty when the file is deleted.
    if (this.fileInput) {
      this.fileInput.value = '';
    }
  };

  // Sequence of function calls for modalDialog
  deleteFileYes = () => {
    this.setState({ modalOpen: false });
    this.deleteFile();
  };

  deleteFileNo = () => {
    this.setState({ modalOpen: false });
  };

  openModalDialog = (index) => {
    this.setState({ modalOpen: true });
    this.currentIndex = index;
  };

  upload = () => {
    const items = this.state.items;
    this.xhrs = [];

    if (items) {
      this.props.onUploadStart?.();

      // Do not send files to upload if it exceeds the fileSize limit (isError)
      items
        .filter((item) => (!item.cancelled && !item.isError))
        .forEach((item) => {
          this.uploadItem(item);
        });
    }
  };

  uploadItem = (item) => {
    if (this.props.chunks) {
      const BYTES_PER_CHUNK = this.props.chunkSize;
      const SIZE = item.file.size;

      let start = 0;
      let end = BYTES_PER_CHUNK;

      const chunkProgressHandler = (percentage, index) => {
        this.updateFileChunkProgress(item.index, index, percentage);
      };

      let chunkIndex = 0;

      while (start < SIZE) {
        this.uploadChunk(item.file.slice(start, end), chunkIndex += 1, item.file.name, chunkProgressHandler);
        start = end;
        end = start + BYTES_PER_CHUNK;
      }
    }
    else {
      this.uploadFile(item.file, item.index, (progress) => this.updateFileProgress(item.index, progress), (status, statusText, responseText) => this.updateFileStatus(item.index, status, statusText, responseText));
    }
  };

  uploadChunk = (blob, chunkIndex, fileName, progressCallback) => {
    if (blob) {
      const formData = new FormData();
      const xhr = new XMLHttpRequest();
      const metaData = this.props.metaData;
      const requestHeaders = this.props.requestHeaders;

      // Loop through the metaData and pass it as key and value pairs to the FormData.
      // We assume the value is always going to be sent as string data.
      if (metaData) {
        for (const key in metaData) {
          if (metaData.hasOwnProperty(key)) {
            formData.append(key, metaData[key]);
          }
        }
      }

      formData.append(this.props.fieldName, blob, `${fileName}-chunk${chunkIndex}`);

      xhr.onload = () => {
        this.props.onLoadComplete?.(xhr.response);
        progressCallback(100, chunkIndex);
      };

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.floor((e.loaded / e.total) * 100);

          if (progress !== 100) {
            progressCallback(progress, chunkIndex);
          }
        }
      };

      xhr.open(this.props.method, this.props.url, true);

      // Loop through headers objects and set the header and value to the xhr.
      if (requestHeaders) {
        for (const headers in requestHeaders) {
          if (requestHeaders.hasOwnProperty(headers)) {
            xhr.setRequestHeader(headers, requestHeaders[headers]);
          }
        }
      }

      xhr.send(formData);
    }
  };

  uploadFile = (file, index, progressCallback, statusCallBack) => {
    if (file) {
      const formData = new FormData();
      const xhr = new XMLHttpRequest();
      const metaData = this.props.metaData;
      const requestHeaders = this.props.requestHeaders;

      // Loop through the metaData and pass it as key and value pairs to the FormData.
      // We assume the value is always going to be sent as string data.
      if (metaData) {
        for (const key in metaData) {
          if (metaData.hasOwnProperty(key)) {
            formData.append(key, metaData[key]);
          }
        }
      }

      formData.append(this.props.fieldName, file, file.name);

      xhr.onload = () => {
        progressCallback(100);
        statusCallBack(this.xhrs[index].status || 0, this.xhrs[index].statusText, this.xhrs[index].responseText || this.xhrs[index].response);
        this.setState(this.uploadComplete);
      };

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.floor((e.loaded / e.total) * 100);

          // check if the progress is not 100% and call progressCallback method due to this onComplete method being called multiple times. 100% should be updated from onload method.
          if (progress !== 100) {
            progressCallback(progress);
          }
        }
      };

      xhr.open(this.props.method, this.props.url, true);

      // Loop through headers objects and set the header and value to the xhr.
      if (requestHeaders) {
        for (const headers in requestHeaders) {
          if (requestHeaders.hasOwnProperty(headers)) {
            xhr.setRequestHeader(headers, requestHeaders[headers]);
          }
        }
      }

      xhr.onerror = () => {
        console.log('** An error occurred during the transaction');
      };

      xhr.send(formData);
      this.xhrs[index] = xhr;
    }
  };

  allFilesAccepted(files) {
    return files.every((file) => accepts(file, this.props.acceptTypes));
  }

  filesToItems = (files) => {
    const fileItems = Array.prototype.slice.call(files).slice(0, this.props.maxFiles);

    return fileItems.map((f, i) => {
      if (this.props.chunks) {
        const chunkProgress = [];

        for (let j = 0; j <= (f.size / this.props.chunkSize); j += 1) {
          chunkProgress.push(0);
        }

        return { file: f, index: i, progress: 0, cancelled: false, isError: false, errorMessage: '', chunkProgress };
      }

      const fileSize = f.size;

      if (fileSize === 0) {
        const errorMessage = FormatHelper.formatMessage('@@fileSizeZeroError');

        return { file: f, index: i, progress: 0, cancelled: false, isError: true, errorMessage: errorMessage };
      }

      if (this.props.maxSize || this.props.acceptTypes) {
        // Error out when the dropped file is not valid file format
        const acceptFiles = accepts(f, this.props.acceptTypes);

        if (acceptFiles === false) {
          const errorMessage = FormatHelper.formatMessage('@@acceptedFileTypes');

          return {
            file: f,
            index: i,
            progress: 0,
            cancelled: false,
            isError: true,
            errorMessage: errorMessage
          };
        }

        // Error out when filesize exceeds the maximum file size.
        if (fileSize > this.props.maxSize) {
          const maxFileSize = this.renderSize(this.props.maxSize / (1024 * 1024));
          const errorMessage = this.props.fileSizeExceedErrorMessage || `${FormatHelper.formatMessage('@@fileSizeExceeded', { fileSize: maxFileSize })}`;

          return {
            file: f,
            index: i,
            progress: 0,
            cancelled: false,
            isError: true,
            errorMessage: errorMessage
          };
        }
      }

      return {
        file: f,
        index: i,
        progress: 0,
        cancelled: false,
        isError: false,
        errorMessage: ''
      };
    });
  };

  renderSize = (sizeInMB) => {
    const userLocale = LocaleHelper.getUserLocale();

    if (userLocale.toLowerCase() === 'en-us' || userLocale.toLowerCase() === 'en-ca') {
      if (!sizeInMB) {
        return '';
      }

      if (sizeInMB < 1) {
        const calculatedFileSize = sizeInMB * 1024;

        return `${Math.floor(calculatedFileSize * 100) / 100} KB`;
      }

      if (sizeInMB > 1024) {
        const calculatedFileSize = sizeInMB / 1024;

        return `${Math.floor(calculatedFileSize * 100) / 100} GB`;
      }

      return `${Math.floor(sizeInMB * 100) / 100} MB`;
    }

    if (userLocale.toLowerCase() === 'es-us') {
      if (!sizeInMB) {
        return '';
      }

      if (sizeInMB < 1) {
        const calculatedFileSize = sizeInMB * 1024;

        return `${Math.floor(calculatedFileSize * 100) / 100} KB`;
      }

      if (sizeInMB > 1024) {
        const calculatedFileSize = sizeInMB / 1024;

        return `${Math.floor(calculatedFileSize * 100) / 100} ES`;
      }

      return `${Math.floor(sizeInMB * 100) / 100} MB`;
    }

    if (userLocale.toLowerCase() === 'fr-ca') {
      if (!sizeInMB) {
        return '';
      }

      if (sizeInMB < 1) {
        const calculatedFileSize = sizeInMB * 1024;

        return `${Math.floor(calculatedFileSize * 100) / 100} Ko`;
      }

      if (sizeInMB > 1024) {
        const calculatedFileSize = sizeInMB / 1024;

        return `${Math.floor(calculatedFileSize * 100) / 100} FR`;
      }

      return `${Math.floor(sizeInMB * 100) / 100} Mo`;
    }
  };

  renderTargetZone = (enableFileSelection: boolean, ariaRequired) => (
    <div className="targetZone">
      <div className={!enableFileSelection || this.isDisabled ? 'dropZoneDisabled' : 'dropZoneLabel'}>
        <div> {this.dropzoneLabelTranslation}</div>
        <div>{FormatHelper.formatMessage('@@or')}</div>
        <MDFButton
          id={generateId('fileUpload')}
          className="uploadLabelStyle"
          buttonStyle="link"
          iconClass={this.props.uploadIconClass}
          aria-label={this.dropzoneLabelTranslation + ' ' + FormatHelper.formatMessage('@@or') + ' ' + this.uploadTextButtonLabelTranslation}
          aria-describedby="fileSizeLabel"
          required={ariaRequired}
          onClick={this.onUploadFilesClick}
          {...((!enableFileSelection || this.isDisabled) && { disabled: true })}
        >
          {this.uploadTextButtonLabelTranslation}
        </MDFButton>
        <div id="fileSizeLabel" className="fileSizeLabel">
          {this.props.fileSizeLabel}
        </div>
      </div>
    </div>
  );

  renderDropTarget = () => {
    let dropTargetStyle = !this.props.dropTargetStyle ? 'dropTargetStyle' : this.props.dropTargetStyle;
    const items = this.state.items;

    if (this.state.isActive) {
      dropTargetStyle = `${dropTargetStyle} dropTargetActiveStyle`;
    }

    const cancelledItems = items.filter((item) => item.cancelled === true);
    const fileWithError = items.filter((item) => item.isError === true);

    // Hide the dropZone  - for single file upload, if the file is valid and when allowMoreUploads is set to false.
    let dropZoneStyle = (items.length > 0 && this.props.maxFiles === 1 && fileWithError.length === 0 && !this.props.allowMoreUploads) || !this.props.dropTargetZone ? 'hideDiv' : dropTargetStyle;

    // Single upload is cancelled after uploading  show the dropZone back.
    if (items.length > 0) {
      dropZoneStyle = ((cancelledItems.length > 0) && (this.props.maxFiles === 1)) ? dropTargetStyle : dropZoneStyle;
    }

    // file selection should be disabled if disabled property is set to true
    let enableFileSelection = !this.props?.disabled;

    // for single file upload, if allowMoreUploads is set to true then enable the file selection
    if (enableFileSelection && items.length > 0 && this.props.maxFiles === 1 && fileWithError.length === 0 && this.props?.allowMoreUploads) {
      enableFileSelection = true;
    }
    else {
      // get active file items and compare with maxFiles
      enableFileSelection = enableFileSelection && (this.props.maxFiles > (items.length - cancelledItems.length - fileWithError.length));
    }

    const ariaRequired = resolveAriaProperty('MDFFileUpload', 'aria-required', 'ariaRequired', this.props);

    return (
      <div>
        <div
          data-test-id="dropTarget"
          className={dropZoneStyle}
          {...(enableFileSelection && { onClick: this.onClick, onDragEnter: this.onDragEnter, onDragOver: this.onDragOver, onDragLeave: this.onDragLeave, onDrop: this.onDrop })}
        >
          {this.renderTargetZone(enableFileSelection, ariaRequired)}
        </div>
        {this.props.renderFileSet ? this.renderFileSet() : ''}
      </div>
    );
  };

  renderPostAttachmentInfo(item, removeLabel, progressBarStyle, progressPercentage, isAnimation, progressLabelIcon, progressComplete, sizeInMB, iconClass) {
    return (
      <div className={this.props.progressClass}>
        <div className="fileDetails">
          <i className={`${this.props.fileIconClass} fileIconClass`}>&nbsp;</i>
          <span className={!this.props.fileNameStyle ? 'fileName' : this.props.fileNameStyle}>{`${item.file.name}`}</span>
          <button
            tabIndex={0}
            id="cancelUpload"
            aria-label={removeLabel.toLowerCase() === 'delete' ? `Delete the uploaded item ${item.file.name}` : `Cancel the current upload item ${item.file.name}`}
            className={item.isError ? 'hideDiv' : 'removeIcon'}
            onClick={(e) => {
              e.stopPropagation();

              if (removeLabel === FormatHelper.formatMessage('@@Delete')) {
                this.openModalDialog(item.index);
              }
              else {
                this.cancelFile(item.index);
              }
            }}
          >
            <MDFIcon className={iconClass} />
            <span>&nbsp;{removeLabel}</span>
          </button>

        </div>
        <ProgressBar
          className={`${progressBarStyle} progressBar`}
          percentage={progressPercentage}
          animation={isAnimation}
          label={progressPercentage + '%'}
        />
        <i className={progressLabelIcon}>
          {progressComplete}
          <span className={!this.props.fileSizeStyle ? 'fileSize' : this.props.fileSizeStyle}>{this.renderSize(sizeInMB)}</span>
        </i>
      </div>
    );
  }

  renderFileSet = () => {
    const items = this.state.items;
    const id = generateId('MDFModalDialog');

    if (items.length > 0) {
      const { cancelIconClass, completeIconClass } = this.props;
      const cancelledItems = items.filter((item) => item.cancelled === true);
      const filesetStyle = (items.length === cancelledItems.length) ? 'hideDiv' : 'fileSet';

      return (
        <div role="region" aria-live="polite" className={!this.props.filesetStyle ? filesetStyle : this.props.filesetStyle}>
          {
            items.filter((item) => !item.cancelled).map((item) => {
              const file = item.file;
              const sizeInMB = (file.size / (1024 * 1024));
              const iconClass = item.progress < 100 ? cancelIconClass : completeIconClass;
              const removeLabel = item.isError ? '' : (item.progress < 100 ? FormatHelper.formatMessage('@@Cancel') : FormatHelper.formatMessage('@@Delete'));
              const progressComplete = item.isError ? this.props.showErrorMessage && item.errorMessage : (item.progress < 100 ? `${Math.round(item.progress) || 0}% ${FormatHelper.formatMessage('@@Done')}` : this.props.successMessage ? this.props.successMessage : FormatHelper.formatMessage('@@uploadSuccess'));
              const progressLabelIcon = item.isError ? 'progressLabel progressLabelError' : (item.progress < 100 ? 'progressLabel' : 'progressLabel progressLabelIcon');
              const progressBarStyle = item.isError ? 'uploadProgress uploadErrorProgress' : 'uploadProgress';
              const progressPercentage = item.isError ? 100 : item.progress;
              const isAnimation = item.progress >= 1;

              // set isDisabled to true when the file upload is in progress.
              if (item.progress > 0 && item.progress < 100) {
                this.fileInput.disabled = true;
                this.isDisabled = true;
              }
              else {
                this.fileInput.disabled = false;
                this.isDisabled = false;
              }

              return (
                <div key={item.index}>
                  {this.renderPostAttachmentInfo(item, removeLabel, progressBarStyle, progressPercentage, isAnimation, progressLabelIcon, progressComplete, sizeInMB, iconClass)}
                  <MDFModalDialog
                    id={id}
                    show={this.state.modalOpen}
                    title={FormatHelper.formatMessage('@@Delete')}
                    size={'sm'}
                    closeable={true}
                    modalType={'Alert'}
                    onYes={this.deleteFileYes}
                    onNo={this.deleteFileNo}
                  >
                    <h4>{FormatHelper.formatMessage('@@DeleteDialog')}</h4>
                  </MDFModalDialog>
                </div>
              );
            })
          }
        </div>
      );
    }

    return <div />;
  };

  renderButton = () => {
    const uploadButtonStyle = !this.props.uploadButtonStyle ? 'uploadButtonStyle' : this.props.uploadButtonStyle;
    const displayButton = !this.props.auto;
    const items = this.state.items;
    const enabled = items && items.filter((item) => (!item.cancelled && !item.isError && item.progress === 0)).length > 0;
    const classes = classNames({
      [uploadButtonStyle]: true
    });

    if (displayButton) {
      return <MDFButton className={classes} id={this.props.uploadButtonId} {...(!enabled && { disabled: true })} onClick={this.onUploadButtonClick}>{this.uploadButtonLabelTranslation}</MDFButton>;
    }

    return null;
  };

  renderInput = () => {
    const maxFiles = this.props.maxFiles;

    return (
      <input
        style={{ display: 'none' }}
        multiple={maxFiles > 1}
        name="file"
        type="file"
        ref={(c) => {
          if (c) {
            this.fileInput = c;
          }
        }}
        accept={this.props.acceptTypes}
        onChange={this.onFileSelect}
      />
    );
  };

  render() {
    const uploadRoot = !this.props.uploadRootStyle ? 'uploadRoot' : this.props.uploadRootStyle;
    const maxFileSize = this.renderSize(this.props.maxSize / (1024 * 1024));

    return (
      <React.Fragment>
        {
          !this.isMobile && <div className={uploadRoot}>
            {this.renderDropTarget()}
            {this.renderButton()}
            {this.renderInput()}
          </div>
        }
        {
          this.isMobile && <div className="mdf-mobile-file-upload">
            <div className="mdf-mobile-file-upload-button-container">
              <MDFButton onClick={(e) => this.handleMobileSelectClick(e)}>{this.uploadTextButtonLabelTranslation}</MDFButton>
              <MDFButton id={this.props.uploadButtonId + '_mobile'} disabled={!this.state.hasSelectedFiles} iconClass="fa fa-upload" onClick={(e) => this.handleMobileUploadClick(e)}>{this.uploadButtonLabelTranslation}</MDFButton>
            </div>
            {
              this.props.fileSizeLabel && <div className="fileSizeLabel">
                {this.props.fileSizeLabel}
              </div>
            }
            {
              this.state.isUploadSuccessful && <div className="mdf-mobile-file-upload-message-container">
                <span>{this.props.successMessage}</span>
              </div>
            }
            {
              !this.state.isUploadSuccessful && this.state.uploadFailed && <div className="mdf-mobile-file-upload-message-container">
                <span className="mdf-mobile-file-upload-failed">{this.props.fileSizeExceedErrorMessage || `${FormatHelper.formatMessage('@@fileSizeExceeded', { fileSize: maxFileSize })}`}</span>
              </div>
            }
          </div>
        }
      </React.Fragment>
    );
  }
}
