import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  ActionMenu,
  Address,
  AgGrid,
  Alert,
  AutoCompleteAddress,
  Calendar,
  CardAtom,
  CheckboxSet,
  Country,
  CSSLayout,
  CSSLayoutItem,
  CurrencyComponent,
  CustomWizard,
  CustomWizardFooter,
  CustomWizardFooterButton,
  CustomWizardHeader,
  CustomWizardStep,
  CustomWizardSteps,
  DateComponent,
  DatePicker,
  DateRangePicker,
  FilterColumn,
  InlineAlert,
  Interview,
  JourneyMap,
  LabeledText,
  LayoutBox,
  LayoutItem,
  LocalePicker,
  MDFActionMenuOption,
  MDFBadge,
  MDFBusyIndicator,
  MDFButton,
  MDFButton2,
  MDFCard,
  MDFCarousel,
  MDFChart,
  MDFChatButton,
  MDFCircleIcon,
  MDFContentPane,
  MDFCurrencyBox,
  MDFDashboard,
  MDFDataRing,
  MDFDraggable,
  MDFDraggableList,
  MDFDropdownList,
  MDFDropdownMenu,
  MDFDropTarget,
  MDFDualMultiSelect,
  MDFFileUpload,
  MDFFilter,
  MDFFlag,
  MDFFocusPane,
  MDFFormButton,
  MDFFormValidatedField,
  MDFGrid,
  MDFHelpIcon,
  MDFIcon,
  MDFImage,
  MDFInfiniteList,
  MDFLabel,
  MDFLabeledAutoCompleteAddress,
  MDFLabeledDatePicker,
  MDFLabeledDropdownList,
  MDFLabeledNumberBox,
  MDFLabeledSelectBox,
  MDFLabeledTextarea,
  MDFLabeledTextbox,
  MDFLegacyHelpIcon,
  MDFListCard,
  MDFListHeader,
  MDFMaskContent,
  MDFMaskedContent,
  MDFModalDialog,
  MDFModalDialog2,
  MDFNumberBox,
  MDFNumberSpinner,
  MDFObfuscationComponent,
  MDFOpenExternalUri,
  MDFOverlayContent,
  MDFOverlayPopoverComponent,
  MDFPhone,
  MDFPopover,
  MDFPopoverContent,
  MDFPopupDialog,
  MDFPrintButton,
  MDFProgressTracker,
  MDFRadio,
  MDFRequiredField,
  MDFSelectBox,
  MDFSidebar,
  MDFSidePanel,
  MDFSimpleHelpIcon,
  MDFSlideIn,
  MDFSlideIn2,
  MDFSpreadSheet,
  MDFTable,
  MDFTabs,
  MDFTextarea,
  MDFTextbox,
  MDFTimePicker,
  MDFToolBar,
  MDFTree,
  MDFValidatedField,
  MDFValidatedTextField,
  MDFValidationContainer,
  MDFVersoView,
  MDFVerticalNav,
  MDFWizard,
  MDFWizardContainer,
  MDFWizardFooter,
  MDFWizardHeader,
  Messages,
  NavStep,
  NewsFeed,
  NumberComponent,
  PaginatorComponent,
  RadioButtonSet,
  renderGrid,
  renderInfiniteList,
  renderInfiniteSearch,
  renderSpreadSheet,
  renderWfnInfiniteList,
  Router,
  RuleCard,
  RuleCardBottom,
  RuleCardFooter,
  RuleCardHeader,
  RuleCardRows,
  RuleSet,
  SearchBox,
  Section,
  SectionContent,
  SectionHeader,
  SegmentedWizardHeader,
  SegmentFiller,
  Separator,
  Slotted,
  SmallCard,
  SmallCardAction,
  SmallCardBottom,
  SmallCardCircle,
  SmallCardContent,
  SmallCardMiddle,
  SmallCardTitle,
  SmallCardTop,
  SnackBar,
  StepNavigation,
  TaxId,
  TextWithLinksComponent,
  TimeFrame,
  ToggleContent,
  TreeList,
  Video,
  WFNMockShell,
  WFNXBanner,
  WFNXBigCard,
  WFNXCardBody,
  WFNXCollapsibleCard,
  WFNXFilter,
  WFNXIconTitleBar,
  WFNXLeftNav,
  WFNXListCard,
  WFNXMultiButtonGroup,
  WFNXSmallCard,
  WFNXSmallCardFooter,
  WFNXSmallCardHeader,
  WFNXTitleBar,
  WFNXWizardStepBar,
  Wizard,
  WizardStep,
  YesNo
} from '..';

// import icons
import { CheckIcon, HomeIcon } from './components/DefaultIcons';

// ADP React Components
import {
  Accordion,
  ActionLink,
  Avatar,
  Breadcrumbs,
  BusyIcon,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Checkbox,
  CheckboxTree,
  ComboButton,
  DataCoin,
  DropdownButton,
  DropdownList,
  LeftNav,
  ListBox,
  ListView,
  ModalFooter,
  momentLocalizer,
  MultiSelectDropdown,
  OverlayTrigger,
  ProgressBar,
  Radio,
  SecondaryNav,
  SecondaryNavItem,
  setDefaultPopupContainerClass,
  setMessageLocalizer,
  SlideIn,
  Slider,
  StatusIndicator,
  Tile,
  TimePicker,
  Toast,
  ToggleSwitch,
  Tooltip
} from '@synerg/vdl-react-components';

import { Tabs } from './components/tabs';

import { ComponentManager, FeatureFlags, LocaleHelper, RendererManager } from '@adp-wfn/mdf-core';
import { flagTranslations } from './util/flagTranslations';
import { MDFFroalaEditor } from './components/MDFFroalaEditor';
import localeData from './localization/translation.json';

// ADP React Component localizations
import enUSMessages from '@synerg/vdl-react-components/src/locale/en.json';
import enCAMessages from '@synerg/vdl-react-components/src/locale/en-CA.json';
import esUSMessages from '@synerg/vdl-react-components/src/locale/es-US.json';
import frCAMessages from '@synerg/vdl-react-components/src/locale/fr-CA.json';

const adpReactMessages = {
  'en-ca': enCAMessages['en-CA'],
  'en-us': enUSMessages.en,
  'es-us': esUSMessages['es-US'],
  'fr-ca': frCAMessages['fr-CA']
};

function getMessage(messages: object, path: string[]) {
  let i;
  let node = messages;
  const length = path.length;

  for (i = 0; i < length - 1; i++) {
    node = node[path[i]];

    if (typeof node === 'undefined') {
      return undefined;
    }
  }

  return node[path[i]];
}

// Waypoint web components
import {
  SdfAccordion,
  SdfActionArea,
  SdfActionMenu,
  SdfAlert,
  SdfAlertBanner,
  SdfAlertIcon,
  SdfAlertInline,
  SdfAlertToast,
  SdfAppLoader,
  SdfAvatar,
  SdfBadge,
  SdfBox,
  SdfBoxStack,
  SdfBusyIndicator,
  SdfButton,
  SdfButtonGroup,
  SdfButtonLayout,
  SdfCalendar,
  SdfCard,
  SdfCardFooter,
  SdfCardHeader,
  SdfCardHeaderAdvanced,
  SdfCardHeaderCollapsible,
  SdfCarousel,
  SdfCarouselItem,
  SdfChart,
  SdfCheckbox,
  SdfCheckboxGroup,
  SdfCloseButton,
  SdfColumnChart,
  SdfCurrencyInput,
  SdfDashboard,
  SdfDashboardCell,
  SdfDataRing,
  SdfDatePicker,
  SdfDatePickerV2,
  SdfExpandableBox,
  SdfFileUploader,
  SdfFlagLayout,
  SdfFloatingPane,
  SdfFloatingPanePortal,
  SdfFocusPane,
  SdfFormControlLayout,
  SdfGrid,
  SdfHomePageLayout,
  SdfIcon,
  SdfIconButton,
  SdfIconLegacy,
  SdfIconStack,
  SdfInlineModal,
  SdfInput,
  SdfLayoutJustifyEdge,
  SdfLayoutListItem,
  SdfLayoutRow,
  SdfLayoutThreeCol,
  SdfLayoutTwoCol,
  SdfLineChart,
  SdfLink,
  SdfMenu,
  SdfMenuDivider,
  SdfMenuItem,
  SdfMenuTitle,
  SdfMfeLoader,
  SdfMiniCard,
  SdfMultiSelect,
  SdfPageHeaderLayout,
  SdfPageLayout,
  SdfPagination,
  SdfPhoneNumberInput,
  SdfPieChart,
  SdfProgressBar,
  SdfPromoBox,
  SdfQuickStat,
  SdfRadioButton,
  SdfRadioGroup,
  SdfReadonlyField,
  SdfScroller,
  SdfSearch,
  SdfSegmentedControl,
  SdfSegmentFiller,
  SdfSelect,
  SdfSelectGroup,
  SdfSelectItem,
  SdfSelectItemList,
  SdfSelectList,
  SdfSelectOption,
  SdfSelectSimple,
  SdfShimmer,
  SdfSkeleton,
  SdfSlider,
  SdfSpinner,
  SdfSpotIllustration,
  SdfSuperTitle,
  SdfSwitch,
  SdfTab,
  SdfTabGroup,
  SdfTable,
  SdfTag,
  SdfTextarea,
  SdfTextEditor,
  SdfTimeline,
  SdfTimelineEvent,
  SdfTimePicker,
  SdfTimePickerV2,
  SdfTimeSpinner,
  SdfToggle,
  SdfTooltip,
  SdfViewStack,
  SdfWizardLayout,
  SdfWizardStepsVertical,
  SdfWizardStepsVerticalItem
} from '@waypoint/react-components';

import {
  SdfxCarousel,
  SdfxCarouselItem,
  SdfxConfetti,
  SdfxHelp,
  SdfxHelpDialog,
  SdfxListbox,
  SdfxPagination
} from '@waypoint/react-components/dist/sdfx-components';

import {
  WfnAddress,
  WfnAutocompleteAddress,
  WfnBadge,
  WfnBbqPlateLoader,
  WfnBox,
  WfnCheckboxTree,
  WfnCountry,
  WfnCurrency,
  WfnCurrencyBox,
  WfnDate,
  WfnDatePicker,
  WfnDropdownList,
  WfnEmployeeIdBar,
  WfnExpandableBox,
  WfnFileUploader,
  WfnFocusPane,
  WfnForm,
  WfnFroalaEditor,
  WfnGrid,
  WfnHomeHero,
  WfnHomePageLayout,
  WfnInfiniteList,
  WfnInput,
  WfnIterator,
  WfnMap,
  WfnMfeLoader,
  WfnMultiSelect,
  WfnNewsFeed,
  WfnNewsFeedItem,
  WfnNumberBox,
  WfnObfuscation,
  WfnPageHeaderButton,
  WfnPhoneNumberInput,
  WfnQuickStat,
  WfnRadioButton,
  WfnRadioGroup,
  WfnRightRailLayout,
  WfnShellMegaMenu,
  WfnShellTopNav,
  WfnSimpleHelpIcon,
  WfnSseListener,
  WfnTaxId,
  WfnTaxIdInput,
  WfnTaxIdSelection,
  WfnText,
  WfnTextarea,
  WfnTimelineCard,
  WfnTimelineContainer,
  WfnTracker,
  WfnVerticalWizard
} from '@adp-wfn/mdf-wc-react';

export class MDFComponents {
  private static isRegistered = false;

  static register() {
    if (this.isRegistered) {
      return;
    }

    console.log('MDFComponents.register(): Registering components.');
    const hasOldShell = (window as any).isLegacyAppShell || (window as any).isClassicShell;
    const hasWaypointModal = FeatureFlags.hasFeature('waypointModal');
    const hasWaypointSlideIn = FeatureFlags.hasFeature('waypointSlideIn');

    // MDF Components
    ComponentManager.registerComponent('ActionMenu', ActionMenu);
    ComponentManager.registerComponent('Address', Address);
    ComponentManager.registerComponent('AgGrid', AgGrid);
    ComponentManager.registerComponent('Alert', Alert);
    ComponentManager.registerComponent('AutoCompleteAddress', AutoCompleteAddress);
    ComponentManager.registerComponent('BusyIndicator', MDFBusyIndicator);
    ComponentManager.registerComponent('Calendar', Calendar);
    ComponentManager.registerComponent('CardAtom', CardAtom);
    ComponentManager.registerComponent('CheckboxSet', CheckboxSet);
    ComponentManager.registerComponent('Country', Country);
    ComponentManager.registerComponent('CSSLayout', CSSLayout);
    ComponentManager.registerComponent('CSSLayoutItem', CSSLayoutItem);
    ComponentManager.registerComponent('Currency', CurrencyComponent);
    ComponentManager.registerComponent('CustomWizard', CustomWizard);
    ComponentManager.registerComponent('CustomWizardFooter', CustomWizardFooter);
    ComponentManager.registerComponent('CustomWizardFooterButton', CustomWizardFooterButton);
    ComponentManager.registerComponent('CustomWizardHeader', CustomWizardHeader);
    ComponentManager.registerComponent('CustomWizardStep', CustomWizardStep);
    ComponentManager.registerComponent('CustomWizardSteps', CustomWizardSteps);
    ComponentManager.registerComponent('Date', DateComponent);
    ComponentManager.registerComponent('DatePicker', DatePicker);
    ComponentManager.registerComponent('DateRangePicker', DateRangePicker);
    ComponentManager.registerComponent('FilterColumn', FilterColumn);
    ComponentManager.registerComponent('InlineAlert', InlineAlert);
    ComponentManager.registerComponent('Interview', Interview);
    ComponentManager.registerComponent('JourneyMap', JourneyMap);
    ComponentManager.registerComponent('LabeledText', LabeledText);
    ComponentManager.registerComponent('LayoutBox', LayoutBox);
    ComponentManager.registerComponent('LayoutItem', LayoutItem);
    ComponentManager.registerComponent('LocalePicker', LocalePicker);
    ComponentManager.registerComponent('MaskContent', MDFMaskContent);
    ComponentManager.registerComponent('MDFActionMenu', ActionMenu);
    ComponentManager.registerComponent('MDFActionMenuOption', MDFActionMenuOption);
    ComponentManager.registerComponent('MDFBadge', MDFBadge);

    if (FeatureFlags.hasFeature('mdfButton2') && !hasOldShell) {
      ComponentManager.registerComponent('MDFButton', MDFButton2);
    }
    else {
      ComponentManager.registerComponent('MDFButton', MDFButton);
    }

    ComponentManager.registerComponent('MDFButton2', MDFButton2);
    ComponentManager.registerComponent('MDFCard', MDFCard);
    ComponentManager.registerComponent('MDFCarousel', MDFCarousel);
    ComponentManager.registerComponent('MDFChart', MDFChart);
    ComponentManager.registerComponent('MDFChatButton', MDFChatButton);
    ComponentManager.registerComponent('MDFCircleIcon', MDFCircleIcon);
    ComponentManager.registerComponent('MDFContentPane', MDFContentPane);
    ComponentManager.registerComponent('MDFCurrencyBox', MDFCurrencyBox);
    ComponentManager.registerComponent('MDFDashboard', MDFDashboard);
    ComponentManager.registerComponent('MDFDataRing', MDFDataRing);
    ComponentManager.registerComponent('MDFDraggable', MDFDraggable);
    ComponentManager.registerComponent('MDFDraggableList', MDFDraggableList);
    ComponentManager.registerComponent('MDFDropdownList', MDFDropdownList);
    ComponentManager.registerComponent('MDFDropdownMenu', MDFDropdownMenu);
    ComponentManager.registerComponent('MDFDropTarget', MDFDropTarget);
    ComponentManager.registerComponent('MDFDualMultiSelect', MDFDualMultiSelect);
    ComponentManager.registerComponent('MDFFileUpload', MDFFileUpload);
    ComponentManager.registerComponent('MDFFilter', MDFFilter);
    ComponentManager.registerComponent('MDFFlag', MDFFlag);
    ComponentManager.registerComponent('MDFFocusPane', MDFFocusPane);
    ComponentManager.registerComponent('MDFFormButton', MDFFormButton);
    ComponentManager.registerComponent('MDFFormValidatedAutoCompleteAddress', MDFFormValidatedField(AutoCompleteAddress));
    ComponentManager.registerComponent('MDFFormValidatedCheckbox', MDFFormValidatedField(Checkbox));
    ComponentManager.registerComponent('MDFFormValidatedCurrencyBox', MDFFormValidatedField(MDFCurrencyBox));
    ComponentManager.registerComponent('MDFFormValidatedDatePicker', MDFFormValidatedField(DatePicker));
    ComponentManager.registerComponent('MDFFormValidatedDropdownList', MDFFormValidatedField(MDFDropdownList));
    ComponentManager.registerComponent('MDFFormValidatedDropdownMenu', MDFFormValidatedField(MDFDropdownMenu));
    ComponentManager.registerComponent('MDFFormValidatedDualMultiSelect', MDFFormValidatedField(MDFDualMultiSelect));
    ComponentManager.registerComponent('MDFFormValidatedFroalaEditor', MDFFormValidatedField(MDFFroalaEditor));
    ComponentManager.registerComponent('MDFFormValidatedNumberBox', MDFFormValidatedField(MDFNumberBox));
    ComponentManager.registerComponent('MDFFormValidatedPhone', MDFFormValidatedField(MDFPhone));
    ComponentManager.registerComponent('MDFFormValidatedRadio', MDFFormValidatedField(Radio));
    ComponentManager.registerComponent('MDFFormValidatedSelectBox', MDFFormValidatedField(MDFSelectBox));
    ComponentManager.registerComponent('MDFFormValidatedTextarea', MDFFormValidatedField(MDFTextarea));
    ComponentManager.registerComponent('MDFFormValidatedTextbox', MDFFormValidatedField(MDFTextbox));
    ComponentManager.registerComponent('MDFFormValidatedTimePicker', MDFFormValidatedField(TimePicker));
    ComponentManager.registerComponent('MDFFormValidatedToggleSwitch', MDFFormValidatedField(ToggleSwitch));
    ComponentManager.registerComponent('MDFGrid', MDFGrid);
    ComponentManager.registerComponent('MDFHelpIcon', MDFHelpIcon);
    ComponentManager.registerComponent('MDFIcon', MDFIcon);
    ComponentManager.registerComponent('MDFImage', MDFImage);
    ComponentManager.registerComponent('MDFInfiniteList', MDFInfiniteList);
    ComponentManager.registerComponent('MDFLabel', MDFLabel);
    ComponentManager.registerComponent('MDFLabeledAutoCompleteAddress', MDFLabeledAutoCompleteAddress);
    ComponentManager.registerComponent('MDFLabeledDatePicker', MDFLabeledDatePicker);
    ComponentManager.registerComponent('MDFLabeledDropdownList', MDFLabeledDropdownList);
    ComponentManager.registerComponent('MDFLabeledNumberBox', MDFLabeledNumberBox);
    ComponentManager.registerComponent('MDFLabeledSelectBox', MDFLabeledSelectBox);
    ComponentManager.registerComponent('MDFLabeledTextarea', MDFLabeledTextarea);
    ComponentManager.registerComponent('MDFLabeledTextbox', MDFLabeledTextbox);
    ComponentManager.registerComponent('MDFLeftNav', WFNXLeftNav);
    ComponentManager.registerComponent('MDFLegacyHelpIcon', MDFLegacyHelpIcon);
    ComponentManager.registerComponent('MDFListHeader', MDFListHeader);
    ComponentManager.registerComponent('MDFMaskedContent', MDFMaskedContent);

    if (hasOldShell || !hasWaypointModal) {
      // Keep the old version of the component for modern MDF apps in the legacy iframe or in the old shell.
      ComponentManager.registerComponent('MDFModalDialog', MDFModalDialog);
    }
    else {
      ComponentManager.registerComponent('MDFModalDialog', MDFModalDialog2);
    }

    ComponentManager.registerComponent('MDFNumberBox', MDFNumberBox);
    ComponentManager.registerComponent('MDFObfuscation', MDFObfuscationComponent);
    ComponentManager.registerComponent('MDFOpenExternalUri', MDFOpenExternalUri);
    ComponentManager.registerComponent('MDFPhone', MDFPhone);
    ComponentManager.registerComponent('MDFPopupDialog', MDFPopupDialog);
    ComponentManager.registerComponent('MDFPrintButton', MDFPrintButton);
    ComponentManager.registerComponent('MDFProgressTracker', MDFProgressTracker);
    ComponentManager.registerComponent('MDFSelectBox', MDFSelectBox);
    ComponentManager.registerComponent('MDFSidebar', MDFSidebar);
    ComponentManager.registerComponent('MDFSidePanel', MDFSidePanel);
    ComponentManager.registerComponent('MDFSimpleHelpIcon', MDFSimpleHelpIcon);

    if (hasOldShell || !hasWaypointSlideIn) {
      // Keep the old version of the component for modern MDF apps in the legacy iframe or in the old shell.
      ComponentManager.registerComponent('MDFSlideIn', MDFSlideIn);
    }
    else {
      ComponentManager.registerComponent('MDFSlideIn', MDFSlideIn2);
    }

    ComponentManager.registerComponent('MDFSpreadSheet', MDFSpreadSheet);
    ComponentManager.registerComponent('MDFTable', MDFTable);
    ComponentManager.registerComponent('MDFTabs', MDFTabs);
    ComponentManager.registerComponent('MDFToolBar', MDFToolBar);
    ComponentManager.registerComponent('MDFTree', MDFTree);
    ComponentManager.registerComponent('MDFValidatedAutoCompleteAddress', MDFRequiredField(AutoCompleteAddress));
    ComponentManager.registerComponent('MDFValidatedCheckbox', MDFValidatedField(Checkbox));
    ComponentManager.registerComponent('MDFValidatedCurrencyBox', MDFRequiredField(MDFCurrencyBox));
    ComponentManager.registerComponent('MDFValidatedDatePicker', MDFRequiredField(DatePicker));
    ComponentManager.registerComponent('MDFValidatedDateRangePicker', MDFRequiredField(DateRangePicker));
    ComponentManager.registerComponent('MDFValidatedDropdownList', MDFRequiredField(MDFDropdownList));
    ComponentManager.registerComponent('MDFValidatedDropdownMenu', MDFRequiredField(MDFDropdownMenu));
    ComponentManager.registerComponent('MDFValidatedDualMulti', MDFRequiredField(MDFDualMultiSelect));
    ComponentManager.registerComponent('MDFValidatedNumberBox', MDFRequiredField(MDFNumberBox));
    ComponentManager.registerComponent('MDFValidatedPhone', MDFRequiredField(MDFPhone));
    ComponentManager.registerComponent('MDFValidatedRadio', MDFValidatedField(Radio));
    ComponentManager.registerComponent('MDFValidatedSelectBox', MDFRequiredField(MDFSelectBox));
    ComponentManager.registerComponent('MDFValidatedTextarea', MDFValidatedTextField(MDFTextarea));
    ComponentManager.registerComponent('MDFValidatedTextbox', MDFValidatedTextField(MDFTextbox));
    ComponentManager.registerComponent('MDFValidatedTimePicker', MDFRequiredField(TimePicker));
    ComponentManager.registerComponent('MDFValidatedToggleSwitch', MDFValidatedField(ToggleSwitch));
    ComponentManager.registerComponent('MDFValidationContainer', MDFValidationContainer);
    ComponentManager.registerComponent('MDFVersoView', MDFVersoView);
    ComponentManager.registerComponent('MDFVerticalNav', MDFVerticalNav);
    ComponentManager.registerComponent('MDFWizard', MDFWizard);
    ComponentManager.registerComponent('MDFWizardContainer', MDFWizardContainer);
    ComponentManager.registerComponent('MDFWizardFooter', MDFWizardFooter);
    ComponentManager.registerComponent('MDFWizardHeader', MDFWizardHeader);
    ComponentManager.registerComponent('Messages', Messages);
    ComponentManager.registerComponent('NavStep', NavStep);
    ComponentManager.registerComponent('NewsFeed', NewsFeed);
    ComponentManager.registerComponent('Number', NumberComponent);
    ComponentManager.registerComponent('OverlayContent', MDFOverlayContent);
    ComponentManager.registerComponent('OverlayPopover', MDFOverlayPopoverComponent);
    ComponentManager.registerComponent('Paginator', PaginatorComponent);
    ComponentManager.registerComponent('Popover', MDFPopover);
    ComponentManager.registerComponent('PopoverContent', MDFPopoverContent);
    ComponentManager.registerComponent('RadioButtonSet', RadioButtonSet);
    ComponentManager.registerComponent('Router', Router);
    ComponentManager.registerComponent('RuleCard', RuleCard);
    ComponentManager.registerComponent('RuleCardBottom', RuleCardBottom);
    ComponentManager.registerComponent('RuleCardFooter', RuleCardFooter);
    ComponentManager.registerComponent('RuleCardHeader', RuleCardHeader);
    ComponentManager.registerComponent('RuleCardRows', RuleCardRows);
    ComponentManager.registerComponent('RuleSet', RuleSet);
    ComponentManager.registerComponent('SearchBox', SearchBox);
    ComponentManager.registerComponent('Section', Section);
    ComponentManager.registerComponent('SectionContent', SectionContent);
    ComponentManager.registerComponent('SectionHeader', SectionHeader);
    ComponentManager.registerComponent('SegmentedWizardHeader', SegmentedWizardHeader);
    ComponentManager.registerComponent('SegmentFiller', SegmentFiller);
    ComponentManager.registerComponent('Separator', Separator);
    ComponentManager.registerComponent('Slotted', Slotted);
    ComponentManager.registerComponent('SnackBar', SnackBar);
    ComponentManager.registerComponent('StepNavigation', StepNavigation);
    ComponentManager.registerComponent('TaxId', TaxId);
    ComponentManager.registerComponent('Text', WfnText);
    ComponentManager.registerComponent('Textarea', MDFTextarea);
    ComponentManager.registerComponent('TextBox', MDFTextbox);
    ComponentManager.registerComponent('TextWithLinks', TextWithLinksComponent);
    ComponentManager.registerComponent('TimeFrame', TimeFrame);
    ComponentManager.registerComponent('ToggleContent', ToggleContent);
    ComponentManager.registerComponent('TreeList', TreeList);
    ComponentManager.registerComponent('Video', Video);
    ComponentManager.registerComponent('WFNMockShell', WFNMockShell);
    ComponentManager.registerComponent('Wizard', Wizard);
    ComponentManager.registerComponent('WizardStep', WizardStep);
    ComponentManager.registerComponent('WFNXActionMenu', ActionMenu);
    ComponentManager.registerComponent('WFNXActionMenuOption', MDFActionMenuOption);
    ComponentManager.registerComponent('WFNXBanner', WFNXBanner);
    ComponentManager.registerComponent('WFNXBigCard', WFNXBigCard);
    ComponentManager.registerComponent('WFNXCardBody', WFNXCardBody);
    ComponentManager.registerComponent('WFNXCircleIcon', MDFCircleIcon);
    ComponentManager.registerComponent('WFNXCollapsibleCard', WFNXCollapsibleCard);
    ComponentManager.registerComponent('WFNXFilter', WFNXFilter);
    ComponentManager.registerComponent('WFNXIconTitleBar', WFNXIconTitleBar);
    ComponentManager.registerComponent('WFNXLeftNav', WFNXLeftNav);
    ComponentManager.registerComponent('WFNXListCard', WFNXListCard);
    ComponentManager.registerComponent('WFNXListHeader', MDFListHeader);
    ComponentManager.registerComponent('WFNXMultiButtonGroup', WFNXMultiButtonGroup);
    ComponentManager.registerComponent('WFNXSeparator', Separator);
    ComponentManager.registerComponent('WFNXSmallCard', WFNXSmallCard);
    ComponentManager.registerComponent('WFNXSmallCardFooter', WFNXSmallCardFooter);
    ComponentManager.registerComponent('WFNXSmallCardHeader', WFNXSmallCardHeader);
    ComponentManager.registerComponent('WFNXTitleBar', WFNXTitleBar);
    ComponentManager.registerComponent('WFNXWizardStepBar', WFNXWizardStepBar);
    ComponentManager.registerComponent('YesNo', YesNo);

    // MDF Small Cards
    ComponentManager.registerComponent('SmallCard', SmallCard);
    ComponentManager.registerComponent('SmallCardAction', SmallCardAction);
    ComponentManager.registerComponent('SmallCardBottom', SmallCardBottom);
    ComponentManager.registerComponent('SmallCardCircle', SmallCardCircle);
    ComponentManager.registerComponent('SmallCardContent', SmallCardContent);
    ComponentManager.registerComponent('SmallCardMiddle', SmallCardMiddle);
    ComponentManager.registerComponent('SmallCardTitle', SmallCardTitle);
    ComponentManager.registerComponent('SmallCardTop', SmallCardTop);
    ComponentManager.registerComponent('MDFListCard', MDFListCard);

    // MDF custom renderers
    RendererManager.registerRenderer('Grid', renderGrid);
    RendererManager.registerRenderer('InfiniteList', renderInfiniteList);
    RendererManager.registerRenderer('InfiniteSearch', renderInfiniteSearch);
    RendererManager.registerRenderer('MDFGrid', renderSpreadSheet);
    RendererManager.registerRenderer('MDFSpreadSheet', renderSpreadSheet);

    // ADP React Components
    ComponentManager.registerComponent('Accordion', Accordion);
    ComponentManager.registerComponent('Accordion.Panel', Accordion.Panel);
    ComponentManager.registerComponent('ActionLink', ActionLink);
    ComponentManager.registerComponent('Avatar', Avatar);
    ComponentManager.registerComponent('Breadcrumbs', Breadcrumbs);
    ComponentManager.registerComponent('BusyIcon', BusyIcon);
    ComponentManager.registerComponent('Button', Button);
    ComponentManager.registerComponent('ButtonGroup', ButtonGroup);
    ComponentManager.registerComponent('Card', Card);
    ComponentManager.registerComponent('CardActions', CardActions);
    ComponentManager.registerComponent('CardContent', CardContent);
    ComponentManager.registerComponent('CardTitle', CardTitle);
    ComponentManager.registerComponent('Checkbox', Checkbox);
    ComponentManager.registerComponent('CheckboxTree', CheckboxTree);
    ComponentManager.registerComponent('ComboButton', ComboButton);
    ComponentManager.registerComponent('DataCoin', DataCoin);
    ComponentManager.registerComponent('DropdownButton', DropdownButton);
    ComponentManager.registerComponent('DropdownList', DropdownList);
    ComponentManager.registerComponent('LeftNav', LeftNav);
    ComponentManager.registerComponent('ListBox', ListBox);
    ComponentManager.registerComponent('ListView', ListView);
    ComponentManager.registerComponent('ModalFooter', ModalFooter);
    ComponentManager.registerComponent('MultiSelectDropdown', MultiSelectDropdown);
    ComponentManager.registerComponent('NumberSpinner', MDFNumberSpinner);
    ComponentManager.registerComponent('OverlayTrigger', OverlayTrigger);
    ComponentManager.registerComponent('ProgressBar', ProgressBar);
    ComponentManager.registerComponent('Radio', MDFRadio);
    ComponentManager.registerComponent('SecondaryNav', SecondaryNav);
    ComponentManager.registerComponent('SecondaryNavItem', SecondaryNavItem);
    ComponentManager.registerComponent('SlideIn', SlideIn);
    ComponentManager.registerComponent('SlideInBody', SlideIn.Body);
    ComponentManager.registerComponent('SlideInFooter', SlideIn.Footer);
    ComponentManager.registerComponent('SlideInHeader', SlideIn.Header);
    ComponentManager.registerComponent('SlideInTitle', SlideIn.Title);
    ComponentManager.registerComponent('Slider', Slider);
    ComponentManager.registerComponent('StatusIndicator', StatusIndicator);
    ComponentManager.registerComponent('Tabs', Tabs);
    ComponentManager.registerComponent('Tile', Tile);
    ComponentManager.registerComponent('TimePicker', MDFTimePicker);
    ComponentManager.registerComponent('Toast', Toast);
    ComponentManager.registerComponent('ToggleSwitch', ToggleSwitch);
    ComponentManager.registerComponent('Tooltip', Tooltip);

    // React Transition Group
    ComponentManager.registerComponent('CSSTransition', CSSTransition);
    ComponentManager.registerComponent('TransitionGroup', TransitionGroup);

    // Register Icons
    ComponentManager.registerComponent('CheckIcon', CheckIcon);
    ComponentManager.registerComponent('HomeIcon', HomeIcon);

    // Register Waypoint web components
    MDFComponents.registerWaypointComponents();

    // Register Localization
    // Merge flag translations into the localeData
    Object.keys(localeData).forEach((key) => {
      if (flagTranslations[key]) {
        localeData[key] = Object.assign({}, { ...localeData[key] }, { ...flagTranslations[key] });
      }
    });

    LocaleHelper.setLocaleData(localeData);

    // Setup the message localizer for the ADP React Library
    setMessageLocalizer({
      formatMessage: (path: string | string[], _variables?: string | string[] | object, locale?: string) => {
        const userLocale = locale || LocaleHelper.getUserLocale() || 'en-us';

        if (typeof path === 'string') {
          path = path.split('/');
        }

        path.unshift(userLocale);

        const message = getMessage(adpReactMessages, path);

        if (typeof message !== 'string') {
          console.error(`messageLocalizer.formatMessage(): Message not found: ${path.join('/')}`);
        }

        return message;
      }
    });

    setDefaultPopupContainerClass('mdf');

    // Setup moment for the ADP React Library
    momentLocalizer(LocaleHelper.dateAndTime, true);

    this.isRegistered = true;
    console.log('MDFComponents.register(): Components registered.');
  }

  private static registerWaypointComponents() {
    const config = window['SynergConfig'];

    if (!config) {
      console.error('The Waypoint web components are not yet loaded. They will be registered with the MDF, but they will not work until the components are loaded.');
    }

    ComponentManager.registerComponent('SdfAccordion', SdfAccordion);
    ComponentManager.registerComponent('SdfActionArea', SdfActionArea);
    ComponentManager.registerComponent('SdfActionMenu', SdfActionMenu);
    ComponentManager.registerComponent('SdfAlert', SdfAlert);
    ComponentManager.registerComponent('SdfAlertBanner', SdfAlertBanner);
    ComponentManager.registerComponent('SdfAlertIcon', SdfAlertIcon);
    ComponentManager.registerComponent('SdfAlertInline', SdfAlertInline);
    ComponentManager.registerComponent('SdfAlertToast', SdfAlertToast);
    ComponentManager.registerComponent('SdfAppLoader', SdfAppLoader);
    ComponentManager.registerComponent('SdfAvatar', SdfAvatar);
    ComponentManager.registerComponent('SdfBadge', SdfBadge);
    ComponentManager.registerComponent('SdfBox', SdfBox);
    ComponentManager.registerComponent('SdfBoxStack', SdfBoxStack);
    ComponentManager.registerComponent('SdfBusyIndicator', SdfBusyIndicator);
    ComponentManager.registerComponent('SdfButton', SdfButton);
    ComponentManager.registerComponent('SdfButtonGroup', SdfButtonGroup);
    ComponentManager.registerComponent('SdfButtonLayout', SdfButtonLayout);
    ComponentManager.registerComponent('SdfCalendar', SdfCalendar);
    ComponentManager.registerComponent('SdfCard', SdfCard);
    ComponentManager.registerComponent('SdfCardFooter', SdfCardFooter);
    ComponentManager.registerComponent('SdfCardHeader', SdfCardHeader);
    ComponentManager.registerComponent('SdfCardHeaderAdvanced', SdfCardHeaderAdvanced);
    ComponentManager.registerComponent('SdfCardHeaderCollapsible', SdfCardHeaderCollapsible);
    ComponentManager.registerComponent('SdfCarousel', SdfCarousel);
    ComponentManager.registerComponent('SdfCarouselItem', SdfCarouselItem);
    ComponentManager.registerComponent('SdfChart', SdfChart);
    ComponentManager.registerComponent('SdfCheckbox', SdfCheckbox);
    ComponentManager.registerComponent('SdfCheckboxGroup', SdfCheckboxGroup);
    ComponentManager.registerComponent('SdfCloseButton', SdfCloseButton);
    ComponentManager.registerComponent('SdfColumnChart', SdfColumnChart);
    ComponentManager.registerComponent('SdfCurrencyInput', SdfCurrencyInput);
    ComponentManager.registerComponent('SdfDashboard', SdfDashboard);
    ComponentManager.registerComponent('SdfDashboardCell', SdfDashboardCell);
    ComponentManager.registerComponent('SdfDataRing', SdfDataRing);
    ComponentManager.registerComponent('SdfDatePicker', SdfDatePicker);
    ComponentManager.registerComponent('SdfDatePickerV2', SdfDatePickerV2);
    ComponentManager.registerComponent('SdfExpandableBox', SdfExpandableBox);
    ComponentManager.registerComponent('SdfFileUploader', SdfFileUploader);
    ComponentManager.registerComponent('SdfFlagLayout', SdfFlagLayout);
    ComponentManager.registerComponent('SdfFloatingPane', SdfFloatingPane);
    ComponentManager.registerComponent('SdfFloatingPanePortal', SdfFloatingPanePortal);

    if (FeatureFlags.hasFeature('mdfFocusPane')) {
      ComponentManager.registerComponent('SdfFocusPane', MDFFocusPane);
    }
    else {
      ComponentManager.registerComponent('SdfFocusPane', SdfFocusPane);
    }

    ComponentManager.registerComponent('SdfFormControlLayout', SdfFormControlLayout);
    ComponentManager.registerComponent('SdfGrid', SdfGrid);
    ComponentManager.registerComponent('SdfHomePageLayout', SdfHomePageLayout);
    ComponentManager.registerComponent('SdfIcon', SdfIcon);
    ComponentManager.registerComponent('SdfIconButton', SdfIconButton);
    ComponentManager.registerComponent('SdfIconLegacy', SdfIconLegacy);
    ComponentManager.registerComponent('SdfIconStack', SdfIconStack);
    ComponentManager.registerComponent('SdfInlineModal', SdfInlineModal);
    ComponentManager.registerComponent('SdfInput', SdfInput);
    ComponentManager.registerComponent('SdfLayoutJustifyEdge', SdfLayoutJustifyEdge);
    ComponentManager.registerComponent('SdfLayoutListItem', SdfLayoutListItem);
    ComponentManager.registerComponent('SdfLayoutRow', SdfLayoutRow);
    ComponentManager.registerComponent('SdfLayoutThreeCol', SdfLayoutThreeCol);
    ComponentManager.registerComponent('SdfLayoutTwoCol', SdfLayoutTwoCol);
    ComponentManager.registerComponent('SdfLineChart', SdfLineChart);
    ComponentManager.registerComponent('SdfLink', SdfLink);
    ComponentManager.registerComponent('SdfMenu', SdfMenu);
    ComponentManager.registerComponent('SdfMenuDivider', SdfMenuDivider);
    ComponentManager.registerComponent('SdfMenuItem', SdfMenuItem);
    ComponentManager.registerComponent('SdfMenuTitle', SdfMenuTitle);
    ComponentManager.registerComponent('SdfMfeLoader', SdfMfeLoader);
    ComponentManager.registerComponent('SdfMiniCard', SdfMiniCard);
    ComponentManager.registerComponent('SdfMultiSelect', SdfMultiSelect);
    ComponentManager.registerComponent('SdfPageHeaderLayout', SdfPageHeaderLayout);
    ComponentManager.registerComponent('SdfPageLayout', SdfPageLayout);
    ComponentManager.registerComponent('SdfPagination', SdfPagination);
    ComponentManager.registerComponent('SdfPhoneNumberInput', SdfPhoneNumberInput);
    ComponentManager.registerComponent('SdfPieChart', SdfPieChart);
    ComponentManager.registerComponent('SdfProgressBar', SdfProgressBar);
    ComponentManager.registerComponent('SdfPromoBox', SdfPromoBox);
    ComponentManager.registerComponent('SdfQuickStat', SdfQuickStat);
    ComponentManager.registerComponent('SdfRadioButton', SdfRadioButton);
    ComponentManager.registerComponent('SdfRadioGroup', SdfRadioGroup);
    ComponentManager.registerComponent('SdfReadonlyField', SdfReadonlyField);
    ComponentManager.registerComponent('SdfScroller', SdfScroller);
    ComponentManager.registerComponent('SdfSearch', SdfSearch);
    ComponentManager.registerComponent('SdfSegmentedControl', SdfSegmentedControl);
    ComponentManager.registerComponent('SdfSegmentFiller', SdfSegmentFiller);
    ComponentManager.registerComponent('SdfSelect', SdfSelect);
    ComponentManager.registerComponent('SdfSelectGroup', SdfSelectGroup);
    ComponentManager.registerComponent('SdfSelectItem', SdfSelectItem);
    ComponentManager.registerComponent('SdfSelectItemList', SdfSelectItemList);
    ComponentManager.registerComponent('SdfSelectList', SdfSelectList);
    ComponentManager.registerComponent('SdfSelectOption', SdfSelectOption);
    ComponentManager.registerComponent('SdfSelectSimple', SdfSelectSimple);
    ComponentManager.registerComponent('SdfShimmer', SdfShimmer);
    ComponentManager.registerComponent('SdfSkeleton', SdfSkeleton);
    ComponentManager.registerComponent('SdfSlider', SdfSlider);
    ComponentManager.registerComponent('SdfSpinner', SdfSpinner);
    ComponentManager.registerComponent('SdfSpotIllustration', SdfSpotIllustration);
    ComponentManager.registerComponent('SdfSuperTitle', SdfSuperTitle);
    ComponentManager.registerComponent('SdfSwitch', SdfSwitch);
    ComponentManager.registerComponent('SdfTab', SdfTab);
    ComponentManager.registerComponent('SdfTabGroup', SdfTabGroup);
    ComponentManager.registerComponent('SdfTable', SdfTable);
    ComponentManager.registerComponent('SdfTag', SdfTag);
    ComponentManager.registerComponent('SdfTextarea', SdfTextarea);
    ComponentManager.registerComponent('SdfTextEditor', SdfTextEditor);
    ComponentManager.registerComponent('SdfTimeline', SdfTimeline);
    ComponentManager.registerComponent('SdfTimelineEvent', SdfTimelineEvent);
    ComponentManager.registerComponent('SdfTimePicker', SdfTimePicker);
    ComponentManager.registerComponent('SdfTimePickerV2', SdfTimePickerV2);
    ComponentManager.registerComponent('SdfTimeSpinner', SdfTimeSpinner);
    ComponentManager.registerComponent('SdfToggle', SdfToggle);
    ComponentManager.registerComponent('SdfTooltip', SdfTooltip);
    ComponentManager.registerComponent('SdfViewStack', SdfViewStack);
    ComponentManager.registerComponent('SdfWizardLayout', SdfWizardLayout);
    ComponentManager.registerComponent('SdfWizardStepsVertical', SdfWizardStepsVertical);
    ComponentManager.registerComponent('SdfWizardStepsVerticalItem', SdfWizardStepsVerticalItem);

    // Register sdf extended components
    ComponentManager.registerComponent('SdfxCarousel', SdfxCarousel);
    ComponentManager.registerComponent('SdfxCarouselItem', SdfxCarouselItem);
    ComponentManager.registerComponent('SdfxConfetti', SdfxConfetti);
    ComponentManager.registerComponent('SdfxHelp', SdfxHelp);
    ComponentManager.registerComponent('SdfxHelpDialog', SdfxHelpDialog);
    ComponentManager.registerComponent('SdfxListbox', SdfxListbox);
    ComponentManager.registerComponent('SdfxPagination', SdfxPagination);

    // Register the wfn components
    ComponentManager.registerComponent('WfnAddress', WfnAddress);
    ComponentManager.registerComponent('WfnAutocompleteAddress', WfnAutocompleteAddress);
    ComponentManager.registerComponent('WfnBadge', WfnBadge);
    ComponentManager.registerComponent('WfnBbqPlateLoader', WfnBbqPlateLoader);
    ComponentManager.registerComponent('WfnBox', WfnBox);
    ComponentManager.registerComponent('WfnCheckboxTree', WfnCheckboxTree);
    ComponentManager.registerComponent('WfnCountry', WfnCountry);
    ComponentManager.registerComponent('WfnCurrency', WfnCurrency);
    ComponentManager.registerComponent('WfnCurrencyBox', WfnCurrencyBox);
    ComponentManager.registerComponent('WfnDate', WfnDate);
    ComponentManager.registerComponent('WfnDatePicker', WfnDatePicker);
    ComponentManager.registerComponent('WfnDropdownList', WfnDropdownList);
    ComponentManager.registerComponent('WfnEmployeeIdBar', WfnEmployeeIdBar);
    ComponentManager.registerComponent('WfnExpandableBox', WfnExpandableBox);
    ComponentManager.registerComponent('WfnFileUploader', WfnFileUploader);
    ComponentManager.registerComponent('WfnFocusPane', WfnFocusPane);
    ComponentManager.registerComponent('WfnForm', WfnForm);
    ComponentManager.registerComponent('WfnFroalaEditor', WfnFroalaEditor);
    ComponentManager.registerComponent('WfnGrid', WfnGrid);
    ComponentManager.registerComponent('WfnHomeHero', WfnHomeHero);
    ComponentManager.registerComponent('WfnHomePageLayout', WfnHomePageLayout);
    ComponentManager.registerComponent('WfnInfiniteList', WfnInfiniteList);
    ComponentManager.registerComponent('WfnInput', WfnInput);
    ComponentManager.registerComponent('WfnIterator', WfnIterator);
    ComponentManager.registerComponent('WfnMap', WfnMap);
    ComponentManager.registerComponent('WfnMfeLoader', WfnMfeLoader);
    ComponentManager.registerComponent('WfnMultiSelect', WfnMultiSelect);
    ComponentManager.registerComponent('WfnNewsFeed', WfnNewsFeed);
    ComponentManager.registerComponent('WfnNewsFeedItem', WfnNewsFeedItem);
    ComponentManager.registerComponent('WfnNumberBox', WfnNumberBox);
    ComponentManager.registerComponent('WfnObfuscation', WfnObfuscation);
    ComponentManager.registerComponent('WfnPageHeaderButton', WfnPageHeaderButton);
    ComponentManager.registerComponent('WfnPhoneNumberInput', WfnPhoneNumberInput);
    ComponentManager.registerComponent('WfnQuickStat', WfnQuickStat);
    ComponentManager.registerComponent('WfnRadioButton', WfnRadioButton);
    ComponentManager.registerComponent('WfnRadioGroup', WfnRadioGroup);
    ComponentManager.registerComponent('WfnRightRailLayout', WfnRightRailLayout);
    ComponentManager.registerComponent('WfnShellMegaMenu', WfnShellMegaMenu);
    ComponentManager.registerComponent('WfnShellTopNav', WfnShellTopNav);
    ComponentManager.registerComponent('WfnSimpleHelpIcon', WfnSimpleHelpIcon);
    ComponentManager.registerComponent('WfnSseListener', WfnSseListener);
    ComponentManager.registerComponent('WfnTaxId', WfnTaxId);
    ComponentManager.registerComponent('WfnTaxIdInput', WfnTaxIdInput);
    ComponentManager.registerComponent('WfnTaxIdSelection', WfnTaxIdSelection);
    ComponentManager.registerComponent('WfnText', WfnText);
    ComponentManager.registerComponent('WfnTextarea', WfnTextarea);
    ComponentManager.registerComponent('WfnTimelineCard', WfnTimelineCard);
    ComponentManager.registerComponent('WfnTimelineContainer', WfnTimelineContainer);
    ComponentManager.registerComponent('WfnTracker', WfnTracker);
    ComponentManager.registerComponent('WfnVerticalWizard', WfnVerticalWizard);

    RendererManager.registerRenderer('WfnInfiniteList', renderWfnInfiniteList);
  }
}
